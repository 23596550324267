<template>
  <div v-if="isLoadingBookings" class="loading">Fetching Queue... ⏳</div>
  <table v-else-if="!isLoadingBookings && queueData" class="not-hoverable">
    <thead>
      <th></th>
      <th>Date</th>
      <th>Client</th>
      <th>Vehicle</th>
      <th>Due Date</th>
      <th>Ramp</th>
      <th>Description</th>
      <th>In Queue</th>
    </thead>
    <draggable v-model="queueData" tag="tbody" item-key="key" @change="handleReorder" >
      <template #item="{ element }">
        <tr class="added-item" @click="(e) => handleRoute(e, element.value.id)">
          <td><i class="fas fa-grip-vertical"></i></td>
          <td>
            <input v-if="element.type === 'date'" type="text" class="date" v-model="element.value" />
          </td>
          <td v-if="element.type === 'booking'">
            <InputSearch mode="queue" :initValue="element.value.client?.name" :selectedClient="element.value.client" @selected-client="(client) => handleClient(client, element.value.id)" @add-client="(newName, clearTel) => handleNewClient(newName, clearTel, element.value.id)" />
            <input type="text" v-model="element.value.client.tel" placeholder="Client Phone Number">
          </td>
          <td v-else></td>
          <td v-if="element.type === 'booking'">
            <input type="text" v-model="element.value.details.regNo" placeholder="Reg. No." required>
            <input type="text" v-model="element.value.details.make" placeholder="Car Make & Model">
          </td>
          <td v-else></td>
          <td v-if="element.type === 'booking'">
            <input type="datetime-local" :value="element.value.details.dueDate ? toIsoString(new Date(element.value.details.dueDate)) : null" @change="(e) => handleDate('due', e.target.value)">  
          </td>
          <td v-else></td>
          <td v-if="element.type === 'booking'">
            <input type="number" v-model="element.value.details.ramp" min="1" max=3 placeholder="Ramp">
          </td>
          <td v-else></td>
          <td v-if="element.type === 'booking'">
            <input type="text" v-model="element.value.details.description" placeholder="Description...">
          </td>
          <td v-else></td>
          <td v-if="element.type === 'booking'">
            <input type="checkbox" v-model="element.value.settings.inQueue" title="In Queue?">
          </td>
          <td v-else></td>
          <td class="button-area" v-if="element.type === 'booking'">
            <button v-if="!isLoading" @click="() => handleChange(element.value)">Update</button>
            <button v-else disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
            <button v-if="!isLoading" class="danger" @click="() => handleDelete(element.value.id)">Delete</button>
            <button v-else class="danger" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
          </td>
          <td v-else class="button-area">
            <button v-if="!isLoading" @click="() => handleDateUpdate(element.key, element.value)">Update</button>
            <button v-else disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
            <button v-if="!isLoading" class="danger" @click="() => handleDateDelete(element.key)">Delete</button>
            <button v-else class="danger" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
          </td>
        </tr>
      </template>
    </draggable>
    <tbody>
      <tr>
        <td></td>
        <td>
          <input type="text" class="date" placeholder="e.g. 01/01/2020" v-model="newDate" />
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td class="button-area">
          <button v-if="!isLoading" class="secondary" @click="handleDateAdd">Add</button>
          <button v-else class="secondary" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
        </td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td>
          <InputSearch mode="queue" :selectedClient="newBooking.client" @selected-client="handleClient" @add-client="handleNewClient" />
          <input type="text" v-model="newBooking.client.tel" placeholder="Client Phone Number">
        </td>
        <td>
          <input type="text" v-model="newBooking.details.regNo" placeholder="Reg. No." required>
          <input type="text" v-model="newBooking.details.make" placeholder="Car Make & Model">
        </td>
        <td>
          <input type="datetime-local" :value="newBooking.details.dueDate ? toIsoString(new Date(newBooking.details.dueDate)) : null" @change="(e) => handleDate('due', e.target.value)">
        </td>
        <td>
          <input type="number" v-model="newBooking.details.ramp" min="1" max=3 placeholder="Ramp">
        </td>
        <td>
          <input type="text" v-model="newBooking.details.description" placeholder="Description...">
        </td>
        <td>
          <input type="checkbox" v-model="newBooking.settings.inQueue" title="In Queue?">
        </td>
        <td class="button-area">
          <button v-if="!isLoading" class="secondary" @click="handleSave">Add</button>
          <button v-else class="secondary" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import useFetch from '@/composables/useFetch'
import useFirestore from '@/composables/useFirestore'
import useDB from '@/composables/useDB'
import { Toast } from '@/composables/useSwal'
import toIsoString from '@/composables/getIsoDate'
import InputSearch from '../Client/InputSearch.vue'
import draggable from 'vuedraggable'

export default {
  components: { InputSearch, draggable },
  emits: ['status-change'],
  setup(props, { emit }) {
    const router = useRouter()
    const { error, isLoading, fetchData } = useFetch()
    const { documents: bookings, isLoading: isLoadingBookings, listenDocuments } = useFirestore()
    const { data: queue, isLoading: isLoadingQueue, error: queueError, listenData, pushData, updateData, deleteData } = useDB()
    const queueData = ref([])
    const newDate = ref("")
    const newBooking = ref({
      details: {
        regNo: null,
        make: null,
        miles: null,
        description: null,
        startDate: Date.now(),
        dueDate: null,
        ramp: 1
      },
      client: {
        id: null,
        name: null,
        tel: null
      },
      settings: {
        inQueue: true
      }
    })

    listenDocuments(
      'bookings', 
      null, 
      null,
      null,
      null, 
      ['settings.inQueue', '==', true],
      null,
      null,
      null,
      null,
      true
    )
    
    listenData(`queue/rows`)

    if (queueError.value) {
      Toast.fire({
        title: 'Uh-oh...',
        text: `Error: ${queueError.value}`,
        icon: 'error'
      })
    }

    watch(isLoadingQueue, () => {
      emit('status-change', isLoadingQueue.value)
    })

    watch([queue, bookings], () => {
      if (queue.value && bookings.value) {  
        queueData.value = Object.entries(queue.value)
          .sort(([,a],[,b]) => a.position - b.position)
          .map(([key, value]) => {
            const valueToDisplay = value.type === 'date' 
              ? value.value 
              : bookings.value.find(booking => booking.id === value.value) || null
              
            return { key, type: value.type, value: valueToDisplay }
          })
      } else {
        queueData.value = []
      }
    })

    const handleDate = (date, value) => {
      if (date === 'start') {
        newBooking.value.details.startDate = new Date(value).getTime()
      } else if (date === 'due') {
        newBooking.value.details.dueDate = new Date(value).getTime()
      }
    }

    const handleDateAdd = async () => {
      await pushData(`queue/rows`, { "type": "date", "value": newDate.value })

      if (queueError.value) {
        Toast.fire({
          title: 'Uh-oh...',
          text: `Error: ${queueError.value}`,
          icon: 'error'
        })

        return
      }

      newDate.value = ""
    }

    const handleDateUpdate = async (key, value) => {
      await updateData(`queue/rows/${key}`, { "value": value })

      if (queueError.value) {
        Toast.fire({
          title: 'Uh-oh...',
          text: `Error: ${queueError.value}`,
          icon: 'error'
        })
      }
    }

    const handleDateDelete = async (key) => {
      await deleteData(`queue/rows/${key}`)
      
      if (queueError.value) {
        Toast.fire({
          title: 'Uh-oh...',
          text: `Error: ${queueError.value}`,
          icon: 'error'
        })
      }
    }

    const handleReorder = async () => {
      const dataToSend = queueData.value.reduce((a, v, i) => { 
        const key = v.key
        const value = v.type === 'date' ? v.value : v.value.id

        return { [key]: { type: v.type, value: value, position: i }, ...a }
      }, {})

      await updateData(`queue/rows`, dataToSend)

      if (queueError.value) {
        Toast.fire({
          title: 'Oops',
          text: queueError.value,
          icon: 'error'
        })
      }
    }

    const handleClient = (client, existingBooking) => {
      if (existingBooking) {
        const index = bookings.value.findIndex(el => el.id === existingBooking)
        bookings.value[index].client = client
      } else {
        newBooking.value.client = client
      }
    }

    const handleNewClient = (newName, clearTel, existingBooking) => {
      if (existingBooking) {
        const index = bookings.value.findIndex(el => el.id === existingBooking)
        bookings.value[index].client.id = null
        bookings.value[index].client.name = newName
        
        if (clearTel) {
          bookings.value[index].client.tel = null
        }
      } else {
        newBooking.value.client.id = null
        newBooking.value.client.name = newName
        
        if (clearTel) {
          newBooking.value.client.tel = null
        }
      }
    }

    const handleSave = async () => {
      if (!newBooking.value.details.regNo) {
        Toast.fire({
          icon: 'error',
          title: 'Hey there...',
          text: 'Please add a Reg. No.'
        })

        return
      }

      await fetchData(`/bookings`, 'post', newBooking.value)
      
      if (!error.value) {
        Toast.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Booking added successfully'
        })

        newBooking.value = {
          details: {
            regNo: null,
            make: null,
            miles: null,
            description: null,
            startDate: Date.now(),
            dueDate: null,
            ramp: 1
          },
          client: {
            id: null,
            name: null,
            tel: null
          },
          settings: {
            inQueue: true
          }
        }
      } else {     
        Toast.fire({
          icon: 'error',
          title: 'Uh-oh...',
          text: `Error: ${error.value}`
        })
      }
    }

    const handleChange = async (booking) => {
      if (!booking.details.regNo) {
        Toast.fire({
          icon: 'error',
          title: 'Hey there...',
          text: 'Please add a Reg. No.'
        })
        
        return
      }

      await fetchData(`/bookings/${booking.id}`, 'patch', booking)

      if (!error.value) {
        Toast.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Booking updated successfully'
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Uh-oh...',
          text: `Error: ${error.value}`
        })
      }
    }
    
    const handleDelete = async (bookingId) => {
      await fetchData(`/bookings/${bookingId}`, 'delete')

      if (!error.value) {
        Toast.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Booking deleted successfully'
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Uh-oh...',
          text: `Error: ${error.value}`
        })
      }
    }

    const handleRoute = (e, id) => {
      if (!id || e.target.tagName === 'A' || e.target.tagName === 'INPUT' || e.target.tagName === 'BUTTON') {
        return
      }

      router.push({ name: 'Booking', params: { id: id } })
    }

    return { emit, queueData, isLoadingBookings, isLoadingQueue, newDate, newBooking,
      isLoading, toIsoString, handleDate, handleDateAdd, handleDateUpdate, handleDateDelete, 
      handleReorder, handleClient, handleNewClient, handleSave, handleChange, handleDelete,
      handleRoute }
  }
}
</script>

<style scoped>
  .loading {
    font-size: 15px;
    margin: 15px 0;
  }
  tr.added-item, tr.added-item:hover {
    background-color: #dfdffb;
  }
  tr.added-item:hover {
    background-color: var(--main-hover);
    cursor: pointer;
  }
  input.date {
    margin: 0;
    padding: 5px;
    font-size: 16px;
    font-weight: 600;
  }
  input.date::placeholder {
    font-size: 14px;
    font-weight: 500;
  }
  i.fas {
    cursor: grab;
  }
  button {
    padding: 6px 8px;
  }
  button.add {
    padding: 4px 6px;
  }
</style>