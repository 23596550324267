import { ref, watchEffect } from 'vue'
import { ref as dbRef, onValue, update, push, remove } from 'firebase/database'
import { projectDB } from '../firebase/config'

const useDB = () => {
    const isLoading = ref(false)
    const error = ref(null)
    const data = ref(null)

    const listenData = (path) => {
        error.value = null
        
        const unsub = onValue(dbRef(projectDB, path), (snapshot) => {
            data.value = snapshot.val()
            error.value = null
        }, (err) => {
            data.value = null
            error.value = err.message
        })

        watchEffect((onInvalidate) => onInvalidate(() => unsub()))
    }

    const updateData = async (path, updates) => {
        isLoading.value = true
        error.value = null

        try {
            await update(dbRef(projectDB, path), updates)
            error.value = null
            isLoading.value = false
        } catch (err) {
            error.value = err.message
            isLoading.value = false
        }
    }

    const pushData = async (path, updates) => {
        isLoading.value = true
        error.value = null

        try {
            await push(dbRef(projectDB, path), updates)
            error.value = null
            isLoading.value = false
        } catch (err) {
            error.value = err.message
            isLoading.value = false
        }
    }

    const deleteData = async (path) => {
        isLoading.value = true
        error.value = null

        try {
            await remove(dbRef(projectDB, path))
            error.value = null
            isLoading.value = false
        } catch (err) {
            error.value = err.message
            isLoading.value = false
        }
    }

    return { isLoading, error, data, listenData, updateData, pushData, deleteData }
}

export default useDB