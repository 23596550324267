<template>
  <div class="page-header">
    <h3>Queue 📑</h3>
    <span>{{ isLoading ? 'Saving...' : '' }}</span>
  </div>
  <div class="container">
    <div class="inner-container">
      <QueueTable @status-change="(loading) => isLoading = loading" />
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue'
  import QueueTable from '../components/Queue/Table.vue'

  export default {
    components: { QueueTable },
    setup() {
      const isLoading = ref(false)

      return { isLoading }
    }
  }
</script>